  .root {
    display: flex;
    width:100%;
    padding: 8px;
  }

  .cover-md {
    display: block;
    margin-right: 16px;
  }

  .cover-sm {
    width: 100%;
    display: none;
    margin-bottom: 1rem;
  }

  .details {
    display: flex;
    flex-direction: column;
  }
  
  .action {
    width: 10%;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .slider {
    display: flex;
    align-items: center;
    padding-top: 32px;
    padding-left: 16px;
  }

  .playIcon {
    height: 38;
    width: 38;
  }

  .width100 {
    width:100%;
  }

  .item-image {
    width:100%;
  }

  .chipsContainer {
    display: flex;
    flex-direction: column;
  }

  .chipContainer {
    min-width: 180px;
  }

  .grow {
    flex-grow: 1;
  }
 
  .chipContainer.top {
    margin-top: 0.5rem;
  }
 
  .chipContainer.bottom {
    margin-bottom: 0.5rem;
  }
  
  .infoChip {
    width: 100%;
    color: #1e5aa1;
  }

  .actionChip {
    width: 100%;
    color:white;
  }

  .actionChip .MuiChip-icon {
    margin-left: 0px;
    margin-right: 5px;
  }

  .actionChip.MuiButtonBase-root {
    flex-direction: row-reverse;
  }

  .MuiChip-label {
    flex-grow: 1;
    text-align: center;
  }

  @media screen and (max-width: 600px) {
      .cover {
       flex-grow: 1;
      }
    
      .details {
        display: flex;
        flex-direction: column;
      }    

      .cover-md {
        display: none;
      }
      
      .cover-sm {
        display: block;
      }
  }
