.inventory-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center
}

.inventory-column {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 1rem;
  min-width: 250px
}

@media screen and (max-width: 600px) {
    .column {
        width: 70%;
    }

    .inventory-column {
        width: 90%;
        padding: 0px;
    }
}

