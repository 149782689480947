.landing .section {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
}

.landing .section-lg {
    padding: 100px 0;
}

.landing .section-title {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0px;
    padding-bottom: 1rem;
    line-height: 1;
    font-size: 1.75rem;
    font-weight: 500;
}

.landing .section-title-border {
    width: 50px;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    background-color: #2762da;
}

.landing .section-subtitle {
    letter-spacing: 0.02em;
}

.landing .text-muted {
    color: #95a0ab !important;
}

.landing .services-box {
    padding: 15px;
}

.landing .hover-effect {
    transition: all 0.3s ease-in-out;
}

.landing .services-box div {
    font-size: 32px;
    height: 68px;
    width: 68px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgb(108 118 134 / 10%) !important;
    line-height: 68px;
    margin-bottom: 15px;

    color: #346eae!important;
}

.landing .services-box:hover div {
    background-color: #346eae!important ;
    color: #ffffff !important;
    transition: all 0.3s ease-in-out;
  }

.landing .cg {
    background: linear-gradient(to right, #1e5aa1, #019ade);
}

.landing  .h100 {
    height: 100%;
}

.landing .vauto {
    margin-top: auto;
    margin-bottom: auto;
}

.landing .text-white {
    color: #ffffff !important;
}

.landing .row > * {
    position: relative;
}

.landing .text-center {
    text-align: center!important;
}

.landing .home-title {
    font-size: 46px;
    line-height: 64px;
}

.landing .home-desc {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    max-width: 600px;
    margin: 0 auto;
}

.landing .home-desc {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    max-width: 600px;
    margin: 0 auto;
}
.landing .pt-3 {
    padding-top: 1rem!important;
}

.landing p {
    font-size: 14.4px;
    line-height: 24px;
}

.landing .play-shadow {
    border-radius: 50%;
    border: 1px solid transparent;
    width: 82px;
    height: 82px;
    margin: 20px auto;
}

.landing .play-btn {
    border: 2px solid;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: block;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    line-height: 56px;
    margin-top: 10px;
    text-align: center;

    color: white;
    border-color: white;
}

.landing a {
    text-decoration: none !important;
    outline: none;
}


.landing .icon-container {
    display: inline-block;
}

.landing .icon {
    vertical-align: unset !important;
    cursor:pointer;
}

.landing .inventory {
    display: flex;
    justify-content: center;
}

.landing .pwa {
    margin-left: 20px;
}


.landing .MuiDialog-paper{
    overflow-y: visible !important
}

.iosmodal {
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-color:white;
        padding: 2rem;
        width:"50%"
}

@media screen and (max-width: 600px) {
    .iosmodal {
        width: 80%;
    }
}

.iosmodal img {
    margin-bottom: 1rem;
}